// Misc
export const LOAD_ALL_STATIC_CONTENT = "LOAD_ALL_STATIC_CONTENT";
export const AUTHENTICATION = "AUTHENTICATION";
export const STATIC_CONTENT = "STATIC_CONTENT";
export const MODAL = "MODAL";
export const DOCUMENT_VIEWER = "DOCUMENT_VIEWER";
export const SYSTEM_FLAG = "SYSTEM_FLAG";

// Mines
export const CREATE_MINE_RECORD = "CREATE_MINE_RECORD";
export const CREATE_MINE_TYPE = "CREATE_MINE_TYPE";
export const GET_MINE_RECORDS = "GET_MINE_RECORDS";
export const GET_MINE_RECORD = "GET_MINE_RECORD";
export const GET_SUBSCRIBED_MINES = "GET_SUBSCRIBED_MINES";
export const GET_MINE_NAME_LIST = "GET_MINE_NAME_LIST";
export const GET_MINE_BASIC_INFO_LIST = "GET_MINE_BASIC_INFO_LIST";
export const GET_STATUS_OPTIONS = "GET_STATUS_OPTIONS";
export const GET_REGION_OPTIONS = "GET_REGION_OPTIONS";
export const UPDATE_MINE_RECORD = "UPDATE_MINE_RECORD";
export const CREATE_TSF = "CREATE_TSF";
export const EDIT_TSF_REPORT = "EDIT_TSF_REPORT";
export const UPDATE_TSF = "UPDATE_TSF";
export const GET_TSF = "GET_TSF";
export const GET_MINE_DOCUMENTS = "GET_MINE_DOCUMENTS";
export const ARCHIVE_MINE_DOCUMENTS = "ARCHIVE_MINE_DOCUMENTS";
export const MINES = "MINES";
export const GET_MINE_TSF_REQUIRED_REPORTS = "GET_MINE_TSF_REQUIRED_REPORTS";
export const GET_MINE_VERIFIED_STATUS = "GET_MINE_VERIFIED_STATUS";
export const SET_MINE_VERIFIED_STATUS = "SET_MINE_VERIFIED_STATUS";
export const GET_USER_INFO = "GET_USER_INFO";
export const POLL_DOCUMENT_UPLOAD_STATUS = "POLL_DOCUMENT_UPLOAD_STATUS";

// Mine Details
export const GET_TENURE_TYPES = "GET_TENURE_TYPES";
export const GET_DISTURBANCE_OPTIONS = "GET_DISTURBANCE_OPTIONS";
export const GET_COMMODITY_OPTIONS = "GET_COMMODITY_OPTIONS";
export const REMOVE_MINE_TYPE = "REMOVE_MINE_TYPE";

// Parties
export const CREATE_PARTY = "CREATE_PARTY";
export const UPDATE_PARTY = "UPDATE_PARTY";
export const PARTIES = "PARTIES";
export const GET_PARTIES = "GET_PARTIES";
export const GET_PARTY = "GET_PARTY";
export const DELETE_PARTY = "DELETE_PARTY";
export const ADD_MINE_MANAGER = "ADD_MINE_MANAGER";
export const ADD_PERMITTEE = "ADD_PERMITTEE";
export const GET_INSPECTORS = "GET_INSPECTORS";
export const GET_PROJECT_LEADS = "GET_PROJECT_LEADS";
export const GET_PARTY_RELATIONSHIP_TYPES = "GET_PARTY_RELATIONSHIP_TYPES";
export const ADD_PARTY_RELATIONSHIP = "ADD_PARTY_RELATIONSHIP";
export const FETCH_PARTY_RELATIONSHIPS = "FETCH_PARTY_RELATIONSHIPS";
export const REMOVE_PARTY_RELATIONSHIP = "REMOVE_PARTY_RELATIONSHIP";
export const UPDATE_PARTY_RELATIONSHIP = "UPDATE_PARTY_RELATIONSHIP";
export const ADD_DOCUMENT_TO_RELATIONSHIP = "ADD_DOCUMENT_TO_RELATIONSHIP";
export const REMOVE_DOCUMENT_FROM_RELATIONSHIP = "REMOVE_DOCUMENT_FROM_RELATIONSHIP";
export const PARTY_ORGBOOK_ENTITY = "PARTY_ORGBOOK_ENTITY";
export const MERGE_PARTIES = "MERGE_PARTIES";

// OrgBook
export const ORGBOOK = "ORGBOOK";
export const ORGBOOK_SEARCH = "ORGBOOK_SEARCH";
export const ORGBOOK_CREDENTIAL = "ORGBOOK_CREDENTIAL";
export const ORGBOOK_VERIFY = "ORGBOOK_VERIFY";

// Compliance Orders
export const COMPLIANCE = "COMPLIANCE";
export const GET_MINE_COMPLIANCE_INFO = "GET_MINE_COMPLIANCE_INFO";

// MineSpace
export const MINESPACE = "MINESPACE";
export const GET_MINESPACE_USER = "GET_MINESPACE_USER";
export const CREATE_MINESPACE_USER = "CREATE_MINESPACE_USER";
export const DELETE_MINESPACE_USER = "DELETE_MINESPACE_USER";
export const GET_MINESPACE_USER_MINES = "GET_MINESPACE_USER_MINES";

export const UPDATE_MINESPACE_USER_MINES = "UPDATE_MINESPACE_USER_MINES";

// MineSpace EMLI contacts
export const GET_EMLI_CONTACTS = "GET_EMLI_CONTACTS";
export const UPDATE_EMLI_CONTACT = "UPDATE_EMLI_CONTACT";
export const DELETE_EMLI_CONTACT = "DELETE_EMLI_CONTACT";

// Misc Details
export const GET_PROVINCE_CODES = "GET_PROVINCE_CODES";
export const GET_COMPLIANCE_CODES = "GET_COMPLIANCE_CODES";

// Permits
export const PERMITS = "PERMITS";
export const CREATE_PERMIT = "CREATE_PERMIT";
export const GET_PERMITS = "GET_PERMITS";
export const UPDATE_PERMIT = "UPDATE_PERMIT";
export const PATCH_PERMIT = "PATCH_PERMIT";
export const CREATE_PERMIT_AMENDMENT = "CREATE_PERMIT_AMENDMENT";
export const UPDATE_PERMIT_AMENDMENT = "UPDATE_PERMIT_AMENDMENT";
export const GET_PERMIT_AMENDMENT = "GET_PERMIT_AMENDMENT";
export const PERMIT_AMENDMENT_VC = "PERMIT_AMENDMENT_VC";
export const GET_PERMIT_STATUS_OPTIONS = "GET_PERMIT_STATUS_OPTIONS";
export const UPDATE_PERMIT_AMENDMENT_DOCUMENT = "UPDATE_PERMIT_AMENDMENT_DOCUMENT";
export const DELETE_PERMIT = "DELETE_PERMIT";
export const DELETE_PERMIT_AMENDMENT = "DELETE_PERMIT_AMENDMENT";
export const GET_PERMIT_CONDITIONS = "GET_PERMIT_CONDITIONS";
export const CREATE_PERMIT_CONDITION = "CREATE_PERMIT_CONDITION";
export const UPDATE_PERMIT_CONDITION = "UPDATE_PERMIT_CONDITION";
export const DELETE_PERMIT_CONDITION = "DELETE_PERMIT_CONDITION";
export const SET_EDITING_CONDITION_FLAG = "SET_EDITING_CONDITION_FLAG";

// Explosive Storage & Use Permits
export const EXPLOSIVES_PERMITS = "EXPLOSIVES_PERMITS";
export const CREATE_EXPLOSIVES_PERMIT = "CREATE_EXPLOSIVES_PERMIT";
export const GET_EXPLOSIVES_PERMITS = "GET_EXPLOSIVES_PERMITS";
export const UPDATE_EXPLOSIVES_PERMIT = "UPDATE_EXPLOSIVES_PERMIT";
export const DELETE_EXPLOSIVES_PERMIT = "DELETE_EXPLOSIVES_PERMIT";
export const CREATE_EXPLOSIVES_PERMIT_AMENDMENT = "CREATE_EXPLOSIVES_PERMIT_AMENDMENT";
export const UPDATE_EXPLOSIVES_PERMIT_AMENDMENT = "UPDATE_EXPLOSIVES_PERMIT_AMENDMENT";

// Search
export const GET_SEARCH_OPTIONS = "GET_SEARCH_OPTIONS";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_BAR_RESULTS = "GET_SEARCH_BAR_RESULTS";
export const CLEAR_SEARCH_BAR_RESULTS = "CLEAR_SEARCH_BAR_RESULTS";
export const CLEAR_ALL_SEARCH_RESULTS = "CLEAR_ALL_SEARCH_RESULTS";
export const SEARCH = "SEARCH";
export const STORE_SUBSET_SEARCH_RESULTS = "STORE_SUBSET_SEARCH_RESULTS";

// Mine Subscriptions
export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";

// Variances
export const VARIANCES = "VARIANCES";
export const CREATE_MINE_VARIANCE = "CREATE_MINE_VARIANCE";
export const GET_VARIANCES = "GET_VARIANCES";
export const GET_VARIANCE = "GET_VARIANCE";
export const ADD_DOCUMENT_TO_VARIANCE = "ADD_DOCUMENT_TO_VARIANCE";
export const REMOVE_DOCUMENT_FROM_VARIANCE = "REMOVE_DOCUMENT_FROM_VARIANCE";
export const GET_VARIANCE_STATUS_OPTIONS = "GET_VARIANCE_STATUS_OPTIONS";
export const GET_VARIANCE_DOCUMENT_CATEGORY_OPTIONS = "GET_VARIANCE_DOCUMENT_CATEGORY_OPTIONS";
export const UPDATE_MINE_VARIANCE = "UPDATE_MINE_VARIANCE";
export const DELETE_VARIANCE = "DELETE_VARIANCE";

// Projects
export const PROJECTS = "PROJECTS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const PROJECT_SUMMARIES = "PROJECT_SUMMARIES";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const CREATE_MINE_PROJECT_SUMMARY = "CREATE_MINE_PROJECT_SUMMARY";
export const GET_PROJECT_SUMMARIES = "GET_PROJECT_SUMMARIES";
export const GET_PROJECT_SUMMARY = "GET_PROJECT_SUMMARY";
export const GET_PROJECT_SUMMARY_MINISTRY_COMMENTS = "GET_PROJECT_SUMMARY_MINISTRY_COMMENTS";
export const CREATE_PROJECT_SUMMARY_MINISTRY_COMMENTS = "CREATE_PROJECT_SUMMARY_MINISTRY_COMMENTS";
export const ADD_DOCUMENT_TO_PROJECT_SUMMARY = "ADD_DOCUMENT_TO_PROJECT_SUMMARY";
export const REMOVE_DOCUMENT_FROM_PROJECT_SUMMARY = "REMOVE_DOCUMENT_FROM_PROJECT_SUMMARY";
export const UPDATE_MINE_PROJECT_SUMMARY = "UPDATE_MINE_PROJECT_SUMMARY";
export const DELETE_PROJECT_SUMMARY = "DELETE_PROJECT_SUMMARY";
export const POST_NEW_DOCUMENT_VERSION = "POST_NEW_DOCUMENT_VERSION";
export const GET_REQUIREMENTS = "GET_REQUIREMENTS";
export const GET_REQUIREMENT = "GET_REQUIREMENT";
export const GET_INFORMATION_REQUIREMENTS_TABLE = "GET_INFORMATION_REQUIREMENTS_TABLE";
export const INFORMATION_REQUIREMENTS_TABLE = "INFORMATION_REQUIREMENTS_TABLE";
export const UPDATE_INFORMATION_REQUIREMENTS_TABLE = "UPDATE_INFORMATION_REQUIREMENTS_TABLE";
export const UPDATE_INFORMATION_REQUIREMENTS_TABLE_STATUS =
  "UPDATE_INFORMATION_REQUIREMENTS_TABLE_STATUS";
export const REMOVE_DOCUMENT_FROM_INFORMATION_REQUIREMENTS_TABLE =
  "REMOVE_DOCUMENT_FROM_INFORMATION_REQUIREMENTS_TABLE";
export const CREATE_MAJOR_MINES_APPLICATION = "CREATE_MAJOR_MINE_APLICATION";
export const GET_MAJOR_MINES_APPLICATION = "GET_MAJOR_MINES_APPLICATION";
export const UPDATE_MAJOR_MINES_APPLICATION = "UPDATE_MAJOR_MINE_APPLICATION";
export const REMOVE_DOCUMENT_FROM_MAJOR_MINE_APPLICATION =
  "REMOVE_DOCUMENT_FROM_MAJOR_MINE_APPLICATION";
export const CREATE_PROJECT_DECISION_PACKAGE = "CREATE_PROJECT_DECISION_PACKAGE";
export const UPDATE_PROJECT_DECISION_PACKAGE = "UPDATE_PROJECT_DECISION_PACKAGE";
export const GET_PROJECT_DECISION_PACKAGE = "GET_PROJECT_DECISION_PACKAGE";
export const REMOVE_DOCUMENT_FROM_PROJECT_DECISION_PACKAGE =
  "REMOVE_DOCUMENT_FROM_PROJECT_DECISION_PACKAGE";
export const UPDATE_DECISION_PACKAGE = "UPDATE_DECISION_PACKAGE";
export const DOCUMENTS_COMPRESSION = "DOCUMENTS_COMPRESSION";
export const POLL_DOCUMENTS_COMPRESSION_PROGRESS = "POLL_DOCUMENTS_COMPRESSION_PROGRESS";
export const CREATE_PROJECT_LINKS = "CREATE_PROJECT_LINKS";
export const DELETE_PROJECT_LINK = "DELETE_PROJECT_LINK";

// Core Users
export const USERS = "USERS";
export const GET_CORE_USERS = "GET_CORE_USERS";

// Incidents
export const INCIDENTS = "INCIDENTS";
export const CREATE_MINE_INCIDENT = "CREATE_MINE_INCIDENT";
export const GET_INCIDENTS = "GET_INCIDENTS";
export const GET_MINE_INCIDENTS = "GET_MINE_INCIDENTS";
export const GET_MINE_INCIDENT = "GET_MINE_INCIDENT";
export const UPDATE_MINE_INCIDENT = "UPDATE_MINE_INCIDENT";
export const REMOVE_DOCUMENT_FROM_MINE_INCIDENT = "REMOVE_DOCUMENT_FROM_MINE_INCIDENT";
export const GET_INCIDENT_DOCUMENT_TYPE_OPTIONS = "GET_INCIDENT_DOCUMENT_TYPE_OPTIONS";
export const GET_MINE_INCIDENT_FOLLOWUP_ACTION_OPTIONS =
  "GET_MINE_INCIDENT_FOLLOWUP_ACTION_OPTIONS";
export const GET_MINE_INCIDENT_DETERMINATION_OPTIONS = "GET_MINE_INCIDENT_DETERMINATION_OPTIONS";
export const GET_MINE_INCIDENT_STATUS_CODE_OPTIONS = "GET_MINE_INCIDENT_STATUS_CODE_OPTIONS";
export const GET_MINE_INCIDENT_CATEGORY_CODE_OPTIONS = "GET_MINE_INCIDENT_CATEGORY_CODE_OPTIONS";
export const DELETE_MINE_INCIDENT = "DELETE_MINE_INCIDENT";
export const GET_MINE_INCIDENT_NOTES = "GET_MINE_INCIDENT_NOTES";
export const CREATE_MINE_INCIDENT_NOTE = "CREATE_MINE_INCIDENT_NOTE";
export const DELETE_MINE_INCIDENT_NOTE = "DELETE_MINE_INCIDENT_NOTE";

// Work Information
export const MINE_WORK_INFORMATIONS = "MINE_WORK_INFORMATIONS";
export const CREATE_MINE_WORK_INFORMATION = "CREATE_MINE_WORK_INFORMATION";
export const GET_MINE_WORK_INFORMATIONS = "GET_MINE_WORK_INFORMATIONS";
export const UPDATE_MINE_WORK_INFORMATION = "UPDATE_MINE_WORK_INFORMATION";
export const DELETE_MINE_WORK_INFORMATION = "DELETE_MINE_WORK_INFORMATION";

// Reports
export const REPORTS = "REPORTS";
export const GET_REPORTS = "GET_REPORTS";
export const UPDATE_MINE_REPORT = "UPDATE_MINE_REPORT";
export const CREATE_MINE_REPORT = "CREATE_MINE_REPORT";
export const DELETE_MINE_REPORT = "DELETE_MINE_REPORT";
export const GET_MINE_REPORTS = "GET_MINE_REPORTS";
export const GET_MINE_REPORT = "GET_MINE_REPORT";
export const GET_MINE_REPORT_DEFINITION_OPTIONS = "GET_MINE_REPORT_DEFINITION_OPTIONS";
export const GET_MINE_REPORT_STATUS_OPTIONS = "GET_MINE_REPORT_STATUS_OPTIONS";
export const GET_MINE_REPORT_CATEGORY_OPTIONS = "GET_MINE_REPORT_CATEGORY_OPTIONS";

// Report Comments
export const GET_MINE_REPORT_COMMENTS = "GET_MINE_REPORT_COMMENTS";
export const UPDATE_MINE_REPORT_COMMENT = "UPDATE_MINE_REPORT_COMMENT";
export const CREATE_MINE_REPORT_COMMENT = "CREATE_MINE_REPORT_COMMENT";
export const DELETE_MINE_REPORT_COMMENT = "DELETE_MINE_REPORT_COMMENT";

// Notices of Work
export const NOTICE_OF_WORK = "NOTICE_OF_WORK";
export const GET_NOTICE_OF_WORK_APPLICATIONS = "GET_NOTICE_OF_WORK_APPLICATIONS";
export const GET_NOTICE_OF_WORK_APPLICATION = "GET_NOTICE_OF_WORK_APPLICATION";
export const GET_MINE_NOTICE_OF_WORK_APPLICATIONS = "GET_MINE_NOTICE_OF_WORK_APPLICATIONS";
export const IMPORT_NOTICE_OF_WORK_APPLICATION = "IMPORT_NOTICE_OF_WORK_APPLICATION";
export const CREATE_NOTICE_OF_WORK_APPLICATION_IMPORT_SUBMISSION_DOCUMENTS_JOB =
  "CREATE_NOTICE_OF_WORK_APPLICATION_IMPORT_SUBMISSION_DOCUMENTS_JOB";
export const CREATE_NOTICE_OF_WORK_APPLICATION = "CREATE_NOTICE_OF_WORK_APPLICATION";
export const CREATE_ADMIN_AMENDMENT_APPLICATION = "CREATE_ADMIN_AMENDMENT_APPLICATION";
export const UPDATE_NOTICE_OF_WORK_APPLICATION = "UPDATE_NOTICE_OF_WORK_APPLICATION";
export const GET_IMPORTED_NOTICE_OF_WORK_APPLICATION = "GET_IMPORTED_NOTICE_OF_WORK_APPLICATION";
export const GET_ORIGINAL_NOTICE_OF_WORK_APPLICATION = "GET_ORIGINAL_NOTICE_OF_WORK_APPLICATION";
export const GET_NOTICE_OF_WORK_ACTIVITY_TYPE_OPTIONS = "GET_NOTICE_OF_WORK_ACTIVITY_TYPE_OPTIONS";
export const GET_NOTICE_OF_WORK_UNIT_TYPE_OPTIONS = "GET_NOTICE_OF_WORK_UNIT_TYPE_OPTIONS";
export const GET_NOTICE_OF_WORK_APPLICATION_TYPE_OPTIONS =
  "GET_NOTICE_OF_WORK_APPLICATION_TYPE_OPTIONS";
export const GET_NOTICE_OF_WORK_APPLICATION_STATUS_OPTIONS =
  "GET_NOTICE_OF_WORK_APPLICATION_STATUS_OPTIONS";
export const GET_NOTICE_OF_WORK_APPLICATION_DOCUMENT_TYPE_OPTIONS =
  "GET_NOTICE_OF_WORK_APPLICATION_DOCUMENT_TYPE_OPTIONS";
export const GET_NOW_UNDERGROUND_EXPLORATION_TYPE_OPTIONS =
  "GET_NOW_UNDERGROUND_EXPLORATION_TYPE_OPTIONS";
export const CREATE_NOTICE_OF_WORK_APPLICATION_PROGRESS =
  "CREATE_NOTICE_OF_WORK_APPLICATION_PROGRESS";
export const UPDATE_NOTICE_OF_WORK_APPLICATION_PROGRESS =
  "UPDATE_NOTICE_OF_WORK_APPLICATION_PROGRESS";
export const CREATE_NOTICE_OF_WORK_APPLICATION_REVIEW = "CREATE_NOTICE_OF_WORK_APPLICATION_REVIEW";
export const GET_NOTICE_OF_WORK_APPLICATION_REVIEW = "GET_NOTICE_OF_WORK_APPLICATION_REVIEW";
export const UPDATE_NOTICE_OF_WORK_APPLICATION_REVIEW = "UPDATE_NOTICE_OF_WORK_APPLICATION_REVIEW";
export const GET_NOTICE_OF_WORK_APPLICATION_REVIEW_TYPES =
  "GET_NOTICE_OF_WORK_APPLICATION_REVIEW_TYPES";
export const REMOVE_NOTICE_OF_WORK_APPLICATION_REVIEW = "REMOVE_NOTICE_OF_WORK_APPLICATION_REVIEW";
export const REMOVE_NOTICE_OF_WORK_APPLICATION_DOCUMENT =
  "REMOVE_NOTICE_OF_WORK_APPLICATION_DOCUMENT";
export const GET_NOW_APPLICATION_PROGRESS_STATUS_CODES =
  "GET_NOW_APPLICATION_PROGRESS_STATUS_CODES";
export const ADD_DOCUMENT_TO_NOTICE_OF_WORK = "ADD_DOCUMENT_TO_NOTICE_OF_WORK";
export const SORT_NOTICE_OF_WORK_DOCUMENTS = "SORT_NOTICE_OF_WORK_DOCUMENTS";
export const GET_NOW_APPLICATION_PERMIT_TYPES = "GET_NOW_APPLICATION_PERMIT_TYPES";
export const GET_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB =
  "GET_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB";
export const UPDATE_NOTICE_OF_WORK_STATUS = "UPDATE_NOTICE_OF_WORK_STATUS";
export const CREATE_NOTICE_OF_WORK_APPLICATION_DELAY = "CREATE_NOTICE_OF_WORK_APPLICATION_DELAY";
export const UPDATE_NOTICE_OF_WORK_APPLICATION_DELAY = "UPDATE_NOTICE_OF_WORK_APPLICATION_DELAY";
export const FETCH_NOTICE_OF_WORK_APPLICATION_DELAY = "FETCH_NOTICE_OF_WORK_APPLICATION_DELAY";

// Securities
export const SECURITIES = "SECURITIES";
export const GET_MINE_BONDS = "GET_MINE_BONDS";
export const GET_BOND = "GET_BOND";
export const CREATE_BOND = "CREATE_BOND";
export const UPDATE_BOND = "UPDATE_BOND";
export const TRANSFER_BOND = "TRANSFER_BOND";
export const GET_MINE_RECLAMATION_INVOICES = "GET_MINE_RECLAMATION_INVOICES";
export const CREATE_RECLAMATION_INVOICE = "CREATE_RECLAMATION_INVOICE";
export const UPDATE_RECLAMATION_INVOICE = "UPDATE_RECLAMATION_INVOICE";

// Comments
export const GET_MINE_COMMENTS = "GET_MINE_COMMENTS";
export const CREATE_MINE_COMMENTS = "CREATE_MINE_COMMENTS";
export const DELETE_MINE_COMMENT = "DELETE_MINE_COMMENT";

// EPIC Information
export const GET_MINE_EPIC_INFO = "GET_MINE_EPIC_INFO";
export const EXTERNAL_AUTHS = "EXTERNAL_AUTHS";

// Notices of Departure
export const NOTICES_OF_DEPARTURE = "NOTICES_OF_DEPARTURE";
export const CREATE_NOTICE_OF_DEPARTURE = "CREATE_NOTICE_OF_DEPARTURE";
export const UPDATE_NOTICE_OF_DEPARTURE = "UPDATE_NOTICE_OF_DEPARTURE";
export const GET_NOTICES_OF_DEPARTURE = "GET_NOTICES_OF_DEPARTURE";
export const ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE = "ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE";
export const GET_DETAILED_NOTICE_OF_DEPARTURE = "GET_DETAILED_NOTICE_OF_DEPARTURE";

// Activities
export const ACTIVITIES = "ACTIVITIES";
export const GET_ACTIVITIES = "GET_ACTIVITIES";

// Tailings Storage Facilities
export const TAILINGS = "TAILINGS";

// Dams
export const DAMS = "DAMS";
export const CREATE_DAM = "CREATE_DAM";
export const UPDATE_DAM = "UPDATE_DAM";
export const GET_DAM = "GET_DAM";

// Alerts
export const GET_GLOBAL_ALERTS = "GET_GLOBAL_ALERTS";

//Verifiable Credentials
export const VERIFIABLE_CREDENTIALS = "VERIFIABLE_CREDENTIALS";
export const CREATE_VC_WALLET_CONNECTION_INVITATION = "CREATE_VC_WALLET_CONNECTION_INVITATION";
export const FETCH_VC_WALLET_CONNECTION_INVITATIONS = "FETCH_VC_WALLET_CONNECTION_INVITATIONS";
export const ISSUE_VC = "ISSUE_VC";
export const DELETE_VC_WALLET_CONNECTION = "DELETE_VC_WALLET_CONNECTION";
